import { Box, Drawer, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import Logo from "src/components/LogoSign";
import { isSidebarOpenState } from "src/store";
import SidebarMenu from "./SidebarMenu";
import { UserCard } from "./UserCard";

export default function Sidebar() {
  const theme = useTheme();
  const isMobilMode = useMediaQuery(theme.breakpoints.down("lg"));
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <Drawer
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant={isMobilMode ? "temporary" : "permanent"}
      sx={{
        width: theme.sidebar.width,
      }}
      PaperProps={{
        sx: {
          borderWidth: "0 1px 0 0",
          background: theme.sidebar.background,
        },
      }}
    >
      <Box
        width={theme.sidebar.width}
        minWidth={theme.sidebar.width}
        color={theme.colors.alpha.trueWhite[70]}
        zIndex={7}
        height={"100%"}
        sx={{
          background: theme.sidebar.background,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          pr="2px"
          pl="2px"
          p={2}
          gap={2}
        >
          <Logo variant="icon" width={'80px'} height={'80px'} />
          <UserCard />
        </Stack>
        <SidebarMenu />
      </Box>
    </Drawer>
  );
}
