const { set, format } = require("date-fns");

export function getStartOfDayIso(date = new Date()) {
  const startOfDate = set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return format(startOfDate, "yyyy-MM-dd") + "T00:00:00.000Z";
}

export function getEndOfDayIso(date = new Date()) {
  const startOfDate = set(date, {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  });

  return format(startOfDate, "yyyy-MM-dd") + "T23:59:59.999Z";
}

const PATTERN_DEFAULT_VALUE = {
  HH: "00",
  mm: "00",
  ss: "00",
  SSSS: "00",
  YYYY: "2025",
  yyyy: '2025',
  MM: "01",
  dd: "01",
};

export function convertIsoFormat(dateString, {
  from = "dd.MM.yyyy",
  to = "yyyy-MM-ddTHH:mm:ss:SSSS",
  defaultValues = PATTERN_DEFAULT_VALUE
}) {
  // Does not filter out '\' in strings
  const getPatterns = (dateFormat = "") => {
    const pattern = /[`~!@#$%^&*()T_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
    const patterns = dateFormat.replace(pattern, " ").split(" ");
    return patterns;
  };

  const old = getPatterns(from);
  const dateStringSplit = getPatterns(dateString);
  let newString = to;

  old.forEach((position, index) => {
    newString = newString.replace(position, dateStringSplit?.[index]);
  });

  Object.keys(defaultValues).forEach((position) => {
    if (newString.includes(position))
      newString = newString.replace(position, defaultValues[position]);
  });

  return newString;
}

export function getViewAsCompanyValue() {
  const companyVar = global?.localStorage?.getItem("viewAsCompany");

  return companyVar
    ? {
        viewAsCompanyId: companyVar,
      }
    : {};
}

export function createPseudoMatrix(
  array = [],
  columns = 4,
  getValue = (a) => a
) {
  const matrix = [{}];

  let row = 0;
  let column = 0;

  for (let i = 0; i < array.length; i++) {
    Object.assign(matrix[row], matrix[row], { [getValue(array[i])]: column });

    if (column < columns - 1) {
      column += 1;
    } else {
      column = 0;
      matrix.push({});
      row += 1;
    }
  }

  return matrix;
}

/**
 * Create A lookup table from array of [key, value] pairs
 * @param {*} array array to create lookup table from
 * @param {*} fn funcion for populating the table, must return [key, value] pair
 * @returns Object
 */
export function createLookupTable(array, fn) {
  if (fn === undefined || array === undefined) return;
  return Object.fromEntries(array.map(fn));
}

/**
 * Extends the Map JavaScript class with a bunch of nice-to-have functionality. Those being upsert for object, array and numeric values
 * @method upsertNumberValue - Increments the value with an existing numeric entry, or creates new entry
 * @method upsertObject - Merges the value with an existing existing entry by combining the properties of both objects, or creates a new entry.
 * @method upsertArray - Adds the value to the end of an existing array, or creates a new entry.
 */
export class ExtendedMap extends Map {
  /**
   * Increments the value with an existing numeric entry, or creates new entry
   */
  upsertNumberValue(key, valueToAdd = 1) {
    if (this.has(key) === false) {
      this.set(key, valueToAdd);
    } else if (typeof this.get(key) === "number") {
      this.set(key, this.get(key) + valueToAdd);
    }
  }

  /**
   * Merges the value with an existing existing entry by combining the properties of both objects, or creates a new entry.
   */
  upsertObject(key, newObject = {}) {
    if (this.has(key) === false) {
      this.set(key, newObject);
    } else if (typeof this.get(key) === "object") {
      const existingValue = this.get(key);
      this.set(key, { ...existingValue, ...newObject });
    }
  }

  /**
   * Adds the value to the end of an existing array, or creates a new entry.
   */
  upsertArray(key, newArrayEntry) {
    if (this.has(key) === false) {
      this.set(key, [newArrayEntry]);
    } else {
      const existing = this.get(key);

      existing.push(newArrayEntry);

      this.set(key, existing);
    }
  }

  /**
   * Turns the map into a array
   * @param {Function} fn Callback for populating the array
   */
  toArray(fn) {
    return Array.from(this.entries(), fn);
  }
}

/**
 * Class for setting up and incrementing hashmaps values
 */
export class NumberMap {
  constructor(map = {}) {
    this.object = map;
  }

  increment(key, byValue) {
    if (typeof byValue === "number") {
      const existing = this.object?.[key] || 0;

      this.object = Object.assign({}, this.object, {
        [key]: existing + byValue,
      });
    }
  }

  set(object = {}) {
    this.object = object;
  }
}
