import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import MoreSettings from "src/components/overlayButtons/MoreSettings";
import { ROLES_LEVEL } from "src/constants";
import { useUser } from "src/hooks/useUser";
import Sidebar from "./Sidebar";
import Header from "./Header";

export function BaseLayout({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%'
      }}
    >
      {children}
    </Box>
  )
};

export function SidebarLayout({ children }) {
  const user = useUser();
  const theme = useTheme();
  const isMobilMode = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack
      direction="column"
      sx={{
        height: "100vh",
        ".MuiPageTitle-wrapper": {
          background:
            theme.palette.mode === "dark"
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
        },
      }}
    >
      <Sidebar />
      {isMobilMode && <Header />}
      <MoreSettings
        isSysAdmin={ROLES_LEVEL[user?.role] >= ROLES_LEVEL.sysAdmin}
      />
      <Stack
        paddingLeft={theme.sidebar.width}
        sx={{ [theme.breakpoints.down("lg")]: { padding: 0 } }}
        direction="column"
        height="100vh"
        overflow="auto"
      >
        {children}
      </Stack>
    </Stack>
  );
}
