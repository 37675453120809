import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/nextjs";;
import "i18next";
import fetchJson from "lib/fetchJson";
import { appWithTranslation } from "next-i18next";
import nextI18nextConfig from "next-i18next.config";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import { RecoilEnv, RecoilRoot } from "recoil";
import { DATE_LOCALES } from "src/constants";
import createEmotionCache from "src/createEmotionCache";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { SidebarLayout } from "src/layout";
import ThemeProvider from "src/theme/ThemeProvider";
import { SWRConfig } from "swr";
const clientSideEmotionCache = createEmotionCache();

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const RoutesWithMenu = new Map([
  ["/dashboard"],
  ["/dashboard/companies"],
  ["/dashboard/edges"],
  ["/dashboard/labelgroups"],
  ["/dashboard/labels"],
  ["/dashboard/passings"],
  ["/dashboard/users"],
]);

function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { pathname, locale, route } = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);
  const { getVariable } = useLocalStorage();

  useEffect(() => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      tracesSampleRate: 0.1,
    });
  }, []);

  const dateLocaleAdapter = () => {
    const localStorageDateLocale = DATE_LOCALES?.[getVariable('i18nextLng')];

    if (localStorageDateLocale) return DATE_LOCALES?.[getVariable('i18nextLng')];
    else if (locale && DATE_LOCALES?.[locale]) return DATE_LOCALES[locale];
    else return DATE_LOCALES.en;
  };

  const SidebarWrapper = ({ children }) => {
    if (RoutesWithMenu.has(pathname)) {
      return <SidebarLayout>{children}</SidebarLayout>;
    } else return <>{children}</>;
  };

  Router.events.on("routeChangeStart", nProgress.start);
  Router.events.on("routeChangeError", nProgress.done);
  Router.events.on("routeChangeComplete", nProgress.done);

  return (
    <CacheProvider value={emotionCache}>
      <SWRConfig
        value={{
          fetcher: fetchJson,
          onError: (err) => {
            console.error(err);
          },
        }}
      >
        <Head>
          <title>DTS</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Head>
        <RecoilRoot >
          <ThemeProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={dateLocaleAdapter()}
            >
              <SnackbarProvider maxSnack={1}>
                <CssBaseline enableColorScheme />
                <SidebarWrapper>
                  {getLayout(<Component {...pageProps} />)}
                </SidebarWrapper>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </RecoilRoot>
      </SWRConfig>
    </CacheProvider>
  );
}

export default appWithTranslation(MyApp, nextI18nextConfig);
