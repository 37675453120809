import {
  BusinessCenterRounded,
  LabelRounded,
  LocalShippingRounded,
  PeopleRounded,
  PieChartRounded,
  RouterRounded,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useTheme,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ROLES_LEVEL } from "src/constants";
import { isSidebarOpenState, userStore } from "src/store";

const items = [
  {
    linkTo: "/dashboard",
    Icon: PieChartRounded,
    name: "sidebar.overview",
  },
  {
    linkTo: "/dashboard/passings",
    Icon: LocalShippingRounded,
    name: "sidebar.passings",
  },
  {
    linkTo: "/dashboard/edges",
    Icon: RouterRounded,
    name: "sidebar.edges",
    roleLevel: ROLES_LEVEL.admin,
  },
  {
    linkTo: "/dashboard/labels",
    Icon: LabelRounded,
    name: "sidebar.labels",
  },
  {
    linkTo: "/dashboard/labelgroups",
    Icon: LabelRounded,
    name: "sidebar.labelGroups",
  },
  {
    linkTo: "/dashboard/companies",
    Icon: BusinessCenterRounded,
    name: "sidebar.companies",
    roleLevel: ROLES_LEVEL.admin,
  },
  {
    linkTo: "/dashboard/users",
    Icon: PeopleRounded,
    name: "sidebar.users",
    roleLevel: ROLES_LEVEL.admin,
  },
];

export default function SidebarMenu() {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation("common");
  const user = useRecoilValue(userStore);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);
  const currentRoleLevel = ROLES_LEVEL[user?.role];
  const [selectedItem, setSelectedItem] = useState(
    router.pathname.replace(router.locale, "")
  );

  const handleClickMenuItem = (linkTo = "") => {
    if (router.pathname !== linkTo) {
      setSelectedItem(linkTo);
      router.push(linkTo);
      setIsSidebarOpen(false);
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        border: "none",
        boxShadow: "none",
        backgroundColor: theme.sidebar.background,
      }}
    >
      <List
        sx={{
          paddingTop: 0,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 2,
        }}
        component="nav"
      >
        {items
          .filter(
            ({ roleLevel }) =>
              roleLevel === undefined || roleLevel <= currentRoleLevel
          )
          .map(({ Icon, linkTo, name }) => (
            <ListItemButton
              key={linkTo}
              selected={selectedItem === linkTo}
              onClick={() => handleClickMenuItem(linkTo)}
              sx={{
                padding: 1.3,
                marginBottom: 2,
                borderRadius: 1,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "unset",
                  paddingRight: "16px",
                }}
              >
                <Icon
                  fontSize="small"
                  color={selectedItem === linkTo ? "primary" : "disabled"}
                />
              </ListItemIcon>
              <ListItemText primary={t(name)} />
            </ListItemButton>
          ))}
      </List>
    </Paper>
  );
}
