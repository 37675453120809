export function useLocalStorage() {
  const getVariable = (key = "", fallback = undefined) => {
    const variable = global?.localStorage?.getItem?.(key);

    if (variable) return variable;
    else return fallback;
  };

  const setVariable = (key = "", value = "") => {
    global?.localStorage?.setItem(key, value);
  };

  const theme = getVariable("appTheme");
  const defaultEdge = getVariable("defaultEdgeId");
  const viewAsCompanyId = getVariable("viewAsCompany");
  const locale = getVariable('i18nextLng');

  return {
    theme,
    defaultEdge,
    locale,
    viewAsCompany: viewAsCompanyId ? { viewAsCompanyId } : {},
    getVariable,
    setVariable,
  };
}
