import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  LogoutTwoTone,
  SettingsTwoTone,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { ROLES } from "src/constants";
import {
  isSettingsModalOpenState,
  isSidebarOpenState,
  userStore,
} from "src/store";

export function UserCard() {
  const router = useRouter();
  const { t } = useTranslation("common");
  const user = useRecoilValue(userStore);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useRecoilState(
    isSettingsModalOpenState
  );
  const [isOpen, setOpen] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem("dts-should-redirect");
    localStorage.removeItem("viewAsCompany");
    localStorage.removeItem("defaultEdgeId");

    const res = await axios.get("/api/auth/logout");

    if (res.status === 200) {
      router.push("/auth/login");
    }
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
    setOpen(false);
  }

  return (
    <Stack
      width='100%'
      component={Card}
      direction="column"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          width: "100%",
          minHeight: "60px",
          flex: 1,
          gap: 1,
          padding: 1
        }}
      >
        <Tooltip arrow title={`logged in as ${user?.email}`}>
          <Avatar>{user?.email?.at(0)?.toUpperCase()}</Avatar>
        </Tooltip>
        <Typography
          maxWidth="140px"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {user?.email} <br />
          {user?.role === ROLES.sysAdmin
            ? "DTS"
            : user?.Company?.name || "DTS"}
        </Typography>
        <IconButton onClick={() => setOpen(!isOpen)}>
          {isOpen ? (
            <KeyboardArrowUpOutlined color="action" />
          ) : (
            <KeyboardArrowDownOutlined color="action" />
          )}
        </IconButton>
      </Box>

      <Collapse in={isOpen}>
        <List>
          <ListItemButton
            key="settings"
            onClick={() => {
              setIsSettingsModalOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <SettingsTwoTone color="primary" />
            </ListItemIcon>
            <ListItemText>settings</ListItemText>
          </ListItemButton>
          <ListItemButton key="logout" onClick={handleLogout}>
            <ListItemIcon>
              <LogoutTwoTone color="error" />
            </ListItemIcon>
            <ListItemText>logout</ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
    </Stack>
  );
}
